import React, {useState, useEffect} from 'react'
import { Layout, MenuList, Seo } from '../components'

const WineListPage = () => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(()=>{
        setHasMounted(true)
    },[])
    if(!hasMounted)return null
    return (
        <Seo title={"DIY Wine Kits - On Premise Crafting"}>
            <Layout>
                <MenuList dataSource={'wine'}/>
            </Layout>
        </Seo>
    )
}

export default WineListPage

